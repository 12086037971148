import React, { createContext, useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import FormFrame from "components/FormFrame/FormFrame";
import CustomButton from "components/CustomButtons/Button.js";
import Close from "@material-ui/icons/Close";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.js";

const style = (theme) => ({
    cardTitle,
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px",
        "& small": {
            fontSize: "80%",
            fontWeight: "400",
        },
    },
    ...modalStyle(theme),
});

const ConfirmationDialogContext = createContext();

const useStyles = makeStyles(style);

export function ConfirmationDialogProvider({ children }) {
    const classes = useStyles();

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState({});

    const openDialog = (content) => {
        setDialogContent(content);
        setIsDialogOpen(true);
    };

    const closeDialog = () => {
        setIsDialogOpen(false);
    };

    const handleConfirm = () => {
        closeDialog();
        dialogContent.onConfirm();
    };

    const fullWidth = dialogContent.fullWidth || false;
    const maxWidth = dialogContent.maxWidth || "xs";
    const showActions = dialogContent.showActions || false;
    const customDialog = dialogContent.customDialog || false;
    const confirmButtonStyle = dialogContent.confirmButtonStyle || {};
    const showClose = dialogContent.showClose || false;

    if (customDialog) {
        return (
            <ConfirmationDialogContext.Provider value={{ openDialog, closeDialog }}>
                {children}
                <Dialog
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    open={isDialogOpen}
                    onClose={closeDialog}
                >
                    {dialogContent.title && (
                        <div style={{ display: "flex" }}>
                            <DialogTitle>{dialogContent.title}</DialogTitle>
                            {showClose && (
                                <CustomButton
                                    justIcon
                                    key="close"
                                    aria-label="Close"
                                    color="transparent"
                                    onClick={closeDialog}
                                    style={{
                                        color: "#969696",
                                        marginLeft: "auto",
                                    }}
                                >
                                    <Close className={classes.modalClose} />
                                </CustomButton>
                            )}
                        </div>
                    )}
                    {dialogContent.message && (
                        <DialogContent>{dialogContent.message}</DialogContent>
                    )}
                    {showActions && (
                        <DialogActions>
                            <Button onClick={closeDialog} color="primary">
                                Cancel
                            </Button>
                            <Button
                                onClick={handleConfirm}
                                color="primary"
                                style={confirmButtonStyle}
                            >
                                Confirm
                            </Button>
                        </DialogActions>
                    )}
                </Dialog>
            </ConfirmationDialogContext.Provider>
        );
    }

    return (
        <ConfirmationDialogContext.Provider value={{ openDialog, closeDialog }}>
            {children}
            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={isDialogOpen}
                onClose={closeDialog}
            >
                {dialogContent.title && <DialogTitle>{dialogContent.title}</DialogTitle>}
                {dialogContent.message && (
                    <DialogContent>
                        <FormFrame title={dialogContent.formFrameTitle} closeDialog={closeDialog}>
                            {dialogContent.message}
                        </FormFrame>
                    </DialogContent>
                )}
                {showActions && (
                    <DialogActions>
                        <Button onClick={closeDialog} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleConfirm} color="primary">
                            Confirm
                        </Button>
                    </DialogActions>
                )}
            </Dialog>
        </ConfirmationDialogContext.Provider>
    );
}

export function useConfirmationDialog() {
    return useContext(ConfirmationDialogContext);
}
