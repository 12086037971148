import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "layouts/Auth.js";
import RtlLayout from "layouts/RTL.js";
import AdminLayout from "layouts/Admin.js";

import ProtectedRoute from "components/ProtectedRoute/ProtectedRoute";

import "assets/scss/material-dashboard-pro-react.scss?v=1.10.0";
import { SnackbarProvider } from "hooks/useSnackbar";
import { ConfirmationDialogProvider } from "hooks/useConfirmationDialog";
import { BASE_URL_API_ECOMMERCE } from "services/Http";
import { BASIC_AUTH } from "services/Http";
import { BackdropProvider } from "hooks/useBackdrop";

// Definir la función principal para gestionar la redirección y renderizado
function initializeApp() {
    const gid = localStorage.getItem("_gid");
    console.log("gid", gid);

    const hostname = window.location.hostname;
    const pathname = window.location.pathname;

    // Mapeo de dominios a dlid
    const domainToDlidMap = {
        "friendlyisuzu.com": 52,
        "humberviewparts.ca": 53,
        "reynoldsisuzuparts.com": 48,
        "trucksmartisuzu.com": 54,
        "scarffisuzu.com": 55,
    };

    // Obtener el dominio base
    const baseDomain = hostname.split(".").slice(-2).join(".");
    const dlid = domainToDlidMap[baseDomain];

    // Si hay un dlid, se hace la redirección y no se renderiza la app
    if (dlid) {
        console.log("Redirigiendo antes de renderizar...");

        fetch(`${BASE_URL_API_ECOMMERCE}/dealer?dlid=${dlid}`, {
            headers: new Headers({
                Authorization: `Basic ${BASIC_AUTH}`,
            }),
        })
            .then((response) => response.json())
            .then((dealer) => {
                let dealerDomain = dealer.data.dealerDomain;
                let dealerAdminDomain = dealer.data.dealerAdminDomain;

                dealerDomain =
                    dealerDomain.startsWith("http://") || dealerDomain.startsWith("https://")
                        ? dealerDomain
                        : `https://${dealerDomain}`;

                dealerAdminDomain =
                    dealerAdminDomain.startsWith("http://") ||
                    dealerAdminDomain.startsWith("https://")
                        ? dealerAdminDomain
                        : `https://${dealerAdminDomain}`;

                if (pathname.includes("/admin")) {
                    window.location.href = dealerAdminDomain;
                } else {
                    window.location.href = dealerDomain;
                }
            })
            .catch((error) => console.error("Error al consultar la API:", error));

        return; // No continuar con el renderizado si se redirige
    }

    // Si no hay un dlid, renderizamos la aplicación normalmente
    renderApp();
}

// Función para renderizar la aplicación
function renderApp() {
    ReactDOM.render(
        <SnackbarProvider>
            <ConfirmationDialogProvider>
                <BackdropProvider>
                    <BrowserRouter>
                        <Switch>
                            <Route path="/rtl" component={RtlLayout} />
                            <Route path="/auth" component={AuthLayout} />
                            <ProtectedRoute path="/admin" component={AdminLayout} />
                            <Redirect from="/" to="/admin/dashboard" />
                        </Switch>
                    </BrowserRouter>
                </BackdropProvider>
            </ConfirmationDialogProvider>
        </SnackbarProvider>,
        document.getElementById("root")
    );
}

// Inicializar la aplicación
initializeApp();
