import React, { useState } from "react";
import {
    Box,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    Typography,
    MenuItem,
    TextField,
    Chip,
} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Button from "components/CustomButtons/Button.js";
import AreYouSure from "components/AreYouSure/AreYouSure";
import useFetchDealers from "hooks/useFetchDealers";
import useCloneDealer from "hooks/useCloneDealer";
import { useBackdrop } from "hooks/useBackdrop";
import { useSnackbar } from "hooks/useSnackbar";

import SyncIcon from "@material-ui/icons/Sync";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

export default function CloneDealerFormV2({
    defaultOption = "existingDealer",
    defaultSourceDealer = "",
    defaultTargetDealer = "",
    availableOptions = "both",
    onDealerSelected = () => {},
    closeDialog = () => {},
}) {
    const { dealers = [], isLoading, error } = useFetchDealers();
    const { cloneDealer } = useCloneDealer();
    const { openBackdrop, closeBackdrop } = useBackdrop();
    const openSnackbar = useSnackbar();

    const [cloneOption, setCloneOption] = useState(
        availableOptions === "both" ? defaultOption : availableOptions
    );
    const [sourceDealer, setSourceDealer] = useState(defaultSourceDealer);
    const [targetDealer, setTargetDealer] = useState(defaultTargetDealer);
    const [dialogState, setDialogState] = useState({
        open: false,
        title: "",
        subtitle: "",
    });

    const handleConfirmClone = () => {
        try {
            const sourceDealerData = dealers.find((dealer) => dealer.id === sourceDealer);
            const targetDealerData = dealers.find((dealer) => dealer.id === targetDealer);

            if (!sourceDealerData || !targetDealerData) {
                openSnackbar("error", "Invalid dealer selection. Please try again.");
                return;
            }

            openBackdrop("Creating dealer, please wait");

            cloneDealer(sourceDealer, targetDealer)
                .then(() => {
                    openSnackbar(
                        "success",
                        `Dealer ${targetDealerData.company} cloned successfully.`
                    );
                    closeDialog();
                })
                .catch(() => {
                    openSnackbar("error", "Failed to clone dealer. Please try again later.");
                })
                .finally(() => {
                    closeBackdrop();
                    setDialogState({ open: false, title: "", subtitle: "" });
                });
        } catch (error) {
            openSnackbar("error", "Unexpected error occurred. Please try again.");
            closeBackdrop();
        }
    };

    const handleNewDealer = () => {
        const payload = {
            cloneOption,
            sourceDealer,
            targetDealer: "",
            sourceDealerData: dealers.find((d) => d.id === sourceDealer),
        };

        onDealerSelected(payload);
        closeDialog();
    };

    const handleExistingDealer = () => {
        const sourceDealerData = dealers.find((dealer) => dealer.id === sourceDealer);
        const targetDealerData = dealers.find((dealer) => dealer.id === targetDealer);

        setDialogState({
            open: true,
            title: "Confirm Cloning Dealer",
            subtitle: `Are you sure you want to clone dealer ${sourceDealerData.company} into ${targetDealerData.company}?`,
        });
    };

    const handleAccept = () => {
        if (!sourceDealer) return;

        if (cloneOption === "newDealer") {
            handleNewDealer();
        } else {
            handleExistingDealer();
        }
    };

    const handleCancel = () => {
        setDialogState({
            open: false,
            title: "",
            subtitle: "",
        });
    };

    return (
        <Box p={2}>
            <AreYouSure
                open={dialogState.open}
                title={dialogState.title}
                subtitle={dialogState.subtitle}
                onAccept={handleConfirmClone}
                onCancel={handleCancel}
            />

            <Typography variant="subtitle1" gutterBottom style={{ fontWeight: "bold" }}>
                Select how you want to clone a dealer
            </Typography>

            <Typography variant="body2" gutterBottom>
                You can either copy the data into the current dealer or create a new dealer using an
                existing one as a template.
            </Typography>

            <FormControl component="fieldset">
                <RadioGroup value={cloneOption} onChange={(e) => setCloneOption(e.target.value)}>
                    {/* 🔹 Opción 1: Clonar dentro de un dealer existente */}
                    {availableOptions !== "newDealer" && (
                        <>
                            <FormControlLabel
                                value="existingDealer"
                                control={<Radio />}
                                label={
                                    <Box display="flex" alignItems="center">
                                        <Typography variant="body1" color="textPrimary">
                                            Copy data into the target dealer
                                        </Typography>
                                        <Chip
                                            label="For existing dealers"
                                            size="small"
                                            variant="outlined"
                                            color="default"
                                            icon={<SyncIcon fontSize="small" />}
                                            style={{ marginLeft: 8 }}
                                        />
                                    </Box>
                                }
                            />
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                style={{ marginLeft: 32 }}
                            >
                                Select a source dealer and a target dealer to transfer all data and
                                configurations.
                            </Typography>

                            {cloneOption === "existingDealer" && (
                                <Box display="flex" alignItems="center" mt={2} ml={4}>
                                    <TextField
                                        select
                                        label="Source Dealer"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        value={sourceDealer}
                                        onChange={(e) => setSourceDealer(e.target.value)}
                                    >
                                        <MenuItem value="" disabled>
                                            Select a dealer
                                        </MenuItem>
                                        {dealers
                                            .filter((dealer) => dealer.id !== defaultTargetDealer)
                                            .map((dealer) => (
                                                <MenuItem key={dealer.id} value={dealer.id}>
                                                    {dealer.id} - {dealer.company}
                                                </MenuItem>
                                            ))}
                                    </TextField>

                                    <ArrowForwardIcon style={{ margin: "0 16px" }} />

                                    <TextField
                                        select
                                        label="Target Dealer"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        disabled={!sourceDealer}
                                        value={targetDealer}
                                        onChange={(e) => setTargetDealer(e.target.value)}
                                    >
                                        <MenuItem value="" disabled>
                                            Select a dealer
                                        </MenuItem>
                                        {dealers
                                            .filter((dealer) => dealer.id !== sourceDealer)
                                            .map((dealer) => (
                                                <MenuItem key={dealer.id} value={dealer.id}>
                                                    {dealer.id} - {dealer.company}
                                                </MenuItem>
                                            ))}
                                    </TextField>
                                </Box>
                            )}
                        </>
                    )}

                    <Box style={{ margin: 10 }}></Box>

                    {/* 🔹 Opción 2: Crear un nuevo dealer basándose en otro */}
                    {availableOptions !== "existingDealer" && (
                        <>
                            <FormControlLabel
                                value="newDealer"
                                control={<Radio />}
                                label={
                                    <Box display="flex" alignItems="center">
                                        <Typography variant="body1" color="textPrimary">
                                            Create a new dealer copying data
                                        </Typography>
                                        <Chip
                                            label="For new dealers"
                                            size="small"
                                            variant="outlined"
                                            icon={<AddCircleOutlineIcon fontSize="small" />}
                                            style={{ marginLeft: 8 }}
                                        />
                                    </Box>
                                }
                            />
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                style={{ marginLeft: 32 }}
                            >
                                Select a dealer to use as a template. This will prefill the new
                                dealer form with the same data, which you can modify before saving.
                            </Typography>

                            {cloneOption === "newDealer" && (
                                <Box mt={2} ml={4}>
                                    <TextField
                                        select
                                        label="Source Dealer"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        value={sourceDealer}
                                        onChange={(e) => setSourceDealer(e.target.value)}
                                    >
                                        <MenuItem value="" disabled>
                                            Select a dealer
                                        </MenuItem>
                                        {dealers
                                            .filter((dealer) => dealer.id !== defaultTargetDealer)
                                            .map((dealer) => (
                                                <MenuItem key={dealer.id} value={dealer.id}>
                                                    {dealer.id} - {dealer.company}
                                                </MenuItem>
                                            ))}
                                    </TextField>
                                </Box>
                            )}
                        </>
                    )}
                </RadioGroup>
            </FormControl>

            {/* 🔹 Botón de Acción */}
            <Box mt={3} textAlign="center">
                <Button
                    type="button"
                    color="rose"
                    disabled={!sourceDealer || (cloneOption === "existingDealer" && !targetDealer)}
                    onClick={handleAccept}
                >
                    Accept
                </Button>
            </Box>
        </Box>
    );
}
