import useUser from "hooks/useUser";
import DealerService from "services/DealerService";

const useCloneDealer = () => {
    const [user] = useUser();

    const cloneDealer = async (sourceDealerId, targetDealerId) => {
        if (!user?.completeName) {
            throw new Error("El usuario no tiene un nombre completo definido.");
        }

        return await DealerService.dealerClone(sourceDealerId, targetDealerId, user.completeName);
    };

    return { cloneDealer };
};

export default useCloneDealer;
