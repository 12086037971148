import Button from "components/CustomButtons/Button.js";
import { BackdropProvider } from "hooks/useBackdrop";
import { useConfirmationDialog } from "hooks/useConfirmationDialog";
import CloneDealerForm from "components/CloneDealerForm/CloneDealerFormV2";

export default function CloneDealerButton({
    defaultTargetDealer = null,
    onDealerSelected = () => {},
}) {
    const { openDialog, closeDialog } = useConfirmationDialog();

    const handleClickCloneDealer = () => {
        openDialog({
            customDialog: true,
            fullWidth: true,
            maxWidth: "md",
            message: (
                <BackdropProvider>
                    <CloneDealerForm
                        closeDialog={closeDialog}
                        defaultTargetDealer={defaultTargetDealer}
                        onDealerSelected={onDealerSelected}
                    />
                </BackdropProvider>
            ),
        });
    };

    return (
        <Button color="primary" onClick={handleClickCloneDealer}>
            Clone dealer
        </Button>
    );
}
