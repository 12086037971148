import React, { useState } from "react";
import { TextField, Typography, Paper, Button } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

export default function ExternalDealerFields({ initialValues }) {
    const [showFields, setShowFields] = useState(false);

    return (
        <>
            <Button
                color="primary"
                fullWidth
                onClick={() => setShowFields(!showFields)}
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    textTransform: "none",
                }}
            >
                {showFields ? (
                    <VisibilityOff style={{ marginRight: 8 }} />
                ) : (
                    <Visibility style={{ marginRight: 8 }} />
                )}
                <Typography variant="body1">
                    {showFields
                        ? "Hide Full Registration Form"
                        : "Show Full Registration Form from Web"}
                </Typography>
            </Button>

            {showFields && (
                <Paper elevation={3} style={{ padding: 20, marginTop: 10 }}>
                    {/* Sección: Dealership Information (DI) */}
                    <Typography variant="h6" gutterBottom style={{ fontSize: 16, fontWeight: 600 }}>
                        Dealership Information
                    </Typography>

                    <GridContainer spacing={2}>
                        <GridItem xs={12} sm={6}>
                            <TextField
                                fullWidth
                                margin="dense"
                                label="Dealer Code"
                                value={initialValues.diDealerCode || ""}
                                inputProps={{ readOnly: true }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                            <TextField
                                fullWidth
                                margin="dense"
                                label="Address"
                                value={initialValues.address1 || ""}
                                inputProps={{ readOnly: true }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                            <TextField
                                fullWidth
                                margin="dense"
                                label="Dealership Name"
                                value={initialValues.company || ""}
                                inputProps={{ readOnly: true }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                            <TextField
                                fullWidth
                                margin="dense"
                                label="City"
                                value={initialValues.city || ""}
                                inputProps={{ readOnly: true }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                            <TextField
                                fullWidth
                                margin="dense"
                                label="Job Title"
                                value={initialValues.diJobTitle || ""}
                                inputProps={{ readOnly: true }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                            <TextField
                                fullWidth
                                margin="dense"
                                label="State"
                                value={initialValues.state || ""}
                                inputProps={{ readOnly: true }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                            <TextField
                                fullWidth
                                margin="dense"
                                label="First Name"
                                value={(initialValues.billContact || "").split(" ")[0] || ""}
                                inputProps={{ readOnly: true }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                            <TextField
                                fullWidth
                                margin="dense"
                                label="Zip Code"
                                value={initialValues.zip || ""}
                                inputProps={{ readOnly: true }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                            <TextField
                                fullWidth
                                margin="dense"
                                label="Last Name"
                                value={
                                    (initialValues.billContact || "")
                                        .split(" ")
                                        .slice(1)
                                        .join(" ") || ""
                                }
                                inputProps={{ readOnly: true }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                            <TextField
                                fullWidth
                                margin="dense"
                                label="Contact Phone Number"
                                value={initialValues.billPhone || ""}
                                inputProps={{ readOnly: true }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                            <TextField
                                fullWidth
                                margin="dense"
                                label="Email"
                                value={initialValues.billEmail || ""}
                                inputProps={{ readOnly: true }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                            <TextField
                                fullWidth
                                margin="dense"
                                label="Phone Number of Manager"
                                value={initialValues.diManagerPhone || ""}
                                inputProps={{ readOnly: true }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                            <TextField
                                fullWidth
                                margin="dense"
                                label="Dealer Website"
                                value={initialValues.diDealerWebsite || ""}
                                inputProps={{ readOnly: true }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                            <TextField
                                fullWidth
                                margin="dense"
                                label="Website Provider"
                                value={initialValues.diWebsiteProvider || ""}
                                inputProps={{ readOnly: true }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                            <TextField
                                fullWidth
                                margin="dense"
                                label="DMS Provider"
                                value={initialValues.diDmsProvider || ""}
                                inputProps={{ readOnly: true }}
                            />
                        </GridItem>
                    </GridContainer>

                    {/* Sección: Acknowledgement of Agreement (AA) */}
                    <Typography
                        variant="h6"
                        gutterBottom
                        style={{ fontSize: 16, fontWeight: 600, marginTop: 20 }}
                    >
                        Acknowledgement of Agreement
                    </Typography>

                    <GridContainer spacing={2}>
                        <GridItem xs={12} sm={6}>
                            <TextField
                                fullWidth
                                margin="dense"
                                label="Signature"
                                value={initialValues.aaSignatureName || ""}
                                inputProps={{ readOnly: true }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                            <TextField
                                fullWidth
                                margin="dense"
                                label="Title"
                                value={initialValues.aaTitle || ""}
                                inputProps={{ readOnly: true }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                            <TextField
                                fullWidth
                                margin="dense"
                                label="Email"
                                value={initialValues.aaEmail || ""}
                                inputProps={{ readOnly: true }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                            <TextField
                                fullWidth
                                margin="dense"
                                label="Phone"
                                value={initialValues.aaPhone || ""}
                                inputProps={{ readOnly: true }}
                            />
                        </GridItem>
                    </GridContainer>
                </Paper>
            )}
        </>
    );
}
