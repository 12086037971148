import React, { createContext, useContext, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Backdrop, CircularProgress, Typography } from "@material-ui/core";

const BackdropContext = createContext();

const useStyles = makeStyles((theme) => ({
    backdrop: {
        color: "#fff",
        zIndex: theme.zIndex.drawer + 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    message: {
        marginTop: theme.spacing(2),
    },
}));

export function BackdropProvider({ children, closeOnClick = false }) {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const [dots, setDots] = useState(".");
    const [message, setMessage] = useState("Loading");

    const openBackdrop = (newMessage = "Loading") => {
        setMessage(newMessage);
        setIsOpen(true);
    };

    const closeBackdrop = () => setIsOpen(false);

    useEffect(() => {
        if (isOpen) {
            const interval = setInterval(() => {
                setDots((prevDots) => (prevDots.length < 3 ? prevDots + "." : "."));
            }, 500);
            return () => clearInterval(interval);
        }
    }, [isOpen]);

    return (
        <BackdropContext.Provider value={{ openBackdrop, closeBackdrop }}>
            {children}
            <Backdrop
                className={classes.backdrop}
                open={isOpen}
                onClick={closeOnClick ? closeBackdrop : undefined}
            >
                <CircularProgress color="inherit" />
                <Typography variant="h6" className={classes.message}>
                    {message}
                    {dots}
                </Typography>
            </Backdrop>
        </BackdropContext.Provider>
    );
}

export function useBackdrop() {
    const context = useContext(BackdropContext);
    if (!context) {
        throw new Error("useBackdrop debe ser usado dentro de BackdropProvider");
    }
    return context;
}
