import useSWR from "swr";
import DealerService from "services/DealerService";

const fetchDealers = async () => {
    const response = await DealerService.getAll();
    return response.data;
};

export default function useFetchDealers() {
    const { data: dealers, error, isLoading } = useSWR("dealers", fetchDealers);

    return {
        dealers,
        isLoading,
        error,
    };
}
