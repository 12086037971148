import React, { useState } from "react";
import { Box, Typography, Button as ButtonMUI, TextField, MenuItem } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import AreYouSure from "components/AreYouSure/AreYouSure";
import Button from "components/CustomButtons/Button.js";
import useFetchDealers from "hooks/useFetchDealers";
import { useBackdrop } from "hooks/useBackdrop";
import { useSnackbar } from "hooks/useSnackbar";
import VhostsService from "services/VhostsService";

export default function GoLiveForm({
    defaultSourceDealer = "",
    defaultTargetDealer = "",
    dealerDomain = "",
    isPublished = 0,
    closeDialog = () => {},
}) {
    const { dealers = [], isLoading, error } = useFetchDealers();
    const [dialogState, setDialogState] = useState({
        open: false,
        title: "",
        subtitle: "",
    });
    const [sourceDealer, setSourceDealer] = useState(defaultSourceDealer || "");
    const [targetDealer, setTargetDealer] = useState(defaultTargetDealer || "");
    const { openBackdrop, closeBackdrop } = useBackdrop();
    const openSnackbar = useSnackbar();

    const handleOpenConfirmDialog = () => {
        setDialogState({
            open: true,
            title: "Confirm Go Live",
            subtitle: `Are you sure you want to publish the dealer site? Once confirmed, it will be accessible through ${dealerDomain}.`,
        });
    };

    const handleGoLive = async () => {
        try {
            const targetDealerData = dealers.find((dealer) => dealer.id === targetDealer);

            openBackdrop("Publishing dealer site, please wait");
            const response = await VhostsService.setupVHosts(sourceDealer, targetDealer);
            setDialogState({ open: false, title: "", subtitle: "" });

            openSnackbar(
                "success",
                `The dealership site for ${targetDealerData.company} has been successfully published.`
            );
        } catch (error) {
            openSnackbar("error", "Unexpected error occurred. Please try again.");
        } finally {
            closeBackdrop();
            closeDialog();
        }
    };

    const handleCancel = () => {
        setDialogState({
            open: false,
            title: "",
            subtitle: "",
        });
    };

    return (
        <Box p={2}>
            <AreYouSure
                open={dialogState.open}
                title={dialogState.title}
                subtitle={dialogState.subtitle}
                onAccept={handleGoLive}
                onCancel={handleCancel}
            />

            <Typography variant="subtitle1" gutterBottom style={{ fontWeight: "bold" }}>
                Go Live Confirmation
            </Typography>

            {!dealerDomain && (
                <Alert severity="error" style={{ marginTop: 20 }}>
                    The store URL is missing. Please check your store settings and try again.
                </Alert>
            )}

            {dealerDomain && (
                <>
                    <Typography variant="body2" gutterBottom>
                        This action will complete the setup required to make the dealer site live.
                        Once confirmed, it will be accessible through its designated URL.
                    </Typography>
                    <Box display="flex" alignItems="center" mt={2} ml={0}>
                        <TextField
                            select
                            label="Source Dealer"
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={sourceDealer}
                            onChange={(e) => setSourceDealer(e.target.value)}
                        >
                            <MenuItem value="" disabled>
                                Select a dealer
                            </MenuItem>
                            {dealers
                                .filter((dealer) => dealer.id !== defaultTargetDealer)
                                .map((dealer) => (
                                    <MenuItem key={dealer.id} value={dealer.id}>
                                        {dealer.id} - {dealer.company}
                                    </MenuItem>
                                ))}
                        </TextField>

                        <ArrowForwardIcon style={{ margin: "0 16px" }} />

                        <TextField
                            select
                            label="Target Dealer"
                            variant="outlined"
                            size="small"
                            fullWidth
                            disabled
                            value={targetDealer}
                        >
                            <MenuItem value="" disabled>
                                Select a dealer
                            </MenuItem>
                            {dealers
                                .filter((dealer) => dealer.id !== sourceDealer)
                                .map((dealer) => (
                                    <MenuItem key={dealer.id} value={dealer.id}>
                                        {dealer.id} - {dealer.company}
                                    </MenuItem>
                                ))}
                        </TextField>
                    </Box>
                </>
            )}

            <Box mt={3} textAlign="center">
                <Button
                    type="button"
                    color="rose"
                    disabled={!dealerDomain}
                    onClick={handleOpenConfirmDialog}
                >
                    Accept & Go Live
                </Button>
            </Box>
        </Box>
    );
}
