import PageLayout from "layouts/PageLayout";
import Table from "components/ReactTable/ReactTableV2";
import DeleteButton from "components/DeleteButton/DeleteButton";
import CreateCustomPage from "./CreateCustomPageV2";
import useCustomPage from "hooks/useCustomPage";

export default function CustomPage() {
    const { pages = [], handleDeletePage, fetchPages } = useCustomPage();

    const columns = [
        {
            Header: "URL",
            accessor: "pageUrl",
        },
        {
            Header: "Title",
            accessor: "enTitle",
        },
        {
            Header: "Is active?",
            accessor: "isActive",
            Cell: ({ value }) => (value ? "Y" : "N"),
        },
        {
            Header: "Action",
            Cell: ({ cell }) => (
                <>
                    <CreateCustomPage
                        isEditForm
                        defaultValues={cell.row.original}
                        onRegistrationComplete={fetchPages}
                    />
                    <DeleteButton
                        title="custom page"
                        handleClick={() => handleDeletePage(cell.row.original.id)}
                    />
                </>
            ),
        },
    ];

    return (
        <PageLayout customComponent={<CreateCustomPage onRegistrationComplete={fetchPages} />}>
            <Table
                columns={columns}
                data={pages.filter((p) => p.isVisible === 1)}
                fetchData={fetchPages}
                pageCount={1}
            />
        </PageLayout>
    );
}
