import Button from "components/CustomButtons/Button.js";
import { BackdropProvider } from "hooks/useBackdrop";
import { useConfirmationDialog } from "hooks/useConfirmationDialog";
import GoLiveForm from "components/GoLiveForm/GoLiveForm";
import PublishIcon from "@material-ui/icons/Publish";

export default function GoLiveButtonV2({
    defaultSourceDealer = "",
    defaultTargetDealer = "",
    dealerDomain = "",
    isPublished = 0,
}) {
    const { openDialog, closeDialog } = useConfirmationDialog();

    const handleClickGoLive = () => {
        openDialog({
            customDialog: true,
            fullWidth: true,
            maxWidth: "md",
            message: (
                <BackdropProvider>
                    <GoLiveForm
                        defaultSourceDealer={defaultSourceDealer}
                        defaultTargetDealer={defaultTargetDealer}
                        dealerDomain={dealerDomain}
                        isPublished={isPublished}
                        closeDialog={closeDialog}
                    />
                </BackdropProvider>
            ),
        });
    };

    if (isPublished === 1) {
        return null;
    }

    return (
        <Button color="facebook" onClick={handleClickGoLive}>
            Go Live <PublishIcon style={{ margin: 0 }} />
        </Button>
    );
}
