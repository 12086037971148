import http from "./Http";

const setupVHosts = async (sourceDealerId, targetDealerId) => {
    const res = await http.post(`/vhosts`, {
        sourceDealerId: sourceDealerId,
        targetDealerId: targetDealerId,
    });

    return res.data;
};

const VhostsService = {
    setupVHosts,
};

export default VhostsService;
