import React, { useState, useEffect } from "react";
import {
    Card,
    CardContent,
    FormControlLabel,
    Checkbox,
    IconButton,
    Button,
    Grid,
    Paper,
    Typography,
    TextField,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CountrySelect from "components/CountrySelect/CountrySelect";

//libs
import { useFormik } from "formik";
import * as yup from "yup";

//hooks
import useDealer from "hooks/useDealer";

//Services
import CatalogService from "services/CatalogService";
import VehicleService from "services/VehicleService";
import DealerCatalogService from "services/DealerCatalogService";

export const DEFAULT_INITIAL_DATA = {
    id: "",
    catalogId: "",
    catalogName: "",
    displayName: "",
    enDisplayName: "",
    esDisplayName: "",
    ptDisplayName: "",
    frDisplayName: "",
    memo: "",
    selectionMakesYears: [],
};

const DEFAULT_SELECTED_MAKE_ID = -1;

const validationSchema = yup.object().shape({
    displayName: yup.string().required(),
    enDisplayName: yup.string().required(),
    esDisplayName: yup.string().required(),
    ptDisplayName: yup.string().required(),
    frDisplayName: yup.string().required(),
});

const SelectionYears = ({
    years = [],
    selectedYears = [],
    onToogleSelectAll = () => {},
    onChange = () => {},
    onClose = () => {},
}) => {
    return (
        <>
            <Card variant="outlined">
                <CardContent>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Typography variant="body1">Select Years:</Typography>

                        <IconButton onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <div>
                        <Button
                            color="primary"
                            size="small"
                            onClick={() => onToogleSelectAll(years)}
                        >
                            Select all
                        </Button>
                        /
                        <Button color="primary" size="small" onClick={() => onToogleSelectAll([])}>
                            Deselect all
                        </Button>
                    </div>
                    {years.map((year) => (
                        <FormControlLabel
                            key={year}
                            control={
                                <Checkbox
                                    checked={selectedYears.includes(year)}
                                    onChange={onChange}
                                    name={year.toString()}
                                    value={year}
                                />
                            }
                            label={year}
                        />
                    ))}
                </CardContent>
            </Card>
        </>
    );
};

const CreateDealerCatalog = ({
    isEditing = false,
    initialValues = DEFAULT_INITIAL_DATA,
    onSuccess = () => {},
}) => {
    const { id: dealerId } = useDealer();
    const [catalogs, setCatalogs] = useState([]);
    const [makes, setMakes] = useState([]);
    const [selectedMakes, setSelectedMakes] = useState([]);
    const [selectedMakeId, setSelectedMakeId] = useState(DEFAULT_SELECTED_MAKE_ID);
    const [years, setYears] = useState([]);

    useEffect(() => {
        const getCatalogs = async () => {
            const catalogs = await fetchCatalogs();
            setCatalogs(catalogs);
        };
        getCatalogs();

        return () => {
            setMakes([]);
            setSelectedMakes([]);
            setSelectedMakeId(DEFAULT_SELECTED_MAKE_ID);
            setYears([]);
        };
    }, []);

    useEffect(() => {
        if (initialValues.catalogId && initialValues.dealerId) {
            setSelectedMakes(initialValues.selectionMakesYears.map((s) => s.id));
            handleCatalogSelectionChange(initialValues.catalogId, initialValues.dealerId);
        }

        return () => {};
    }, [initialValues.catalogId, initialValues.dealerId]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: initialValues.id || "",
            catalogId: initialValues.catalogId || "",
            catalogName: initialValues.catalogName || "",
            displayName: initialValues.displayName || "",
            enDisplayName: initialValues.enDisplayName || "",
            esDisplayName: initialValues.esDisplayName || "",
            ptDisplayName: initialValues.ptDisplayName || "",
            frDisplayName: initialValues.frDisplayName || "",
            memo: initialValues.memo || "",
            selectionMakesYears: initialValues.selectionMakesYears || [],
            selectedCountries: initialValues.selectedCountries || "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (isEditing) {
                handleEdit({ ...values, dealerId });
            } else {
                handleCreate({ ...values, dealerId });
            }
        },
    });

    const handleCreate = async (values) => {
        try {
            const res = await DealerCatalogService.post(values);
            console.log("res", res.data);
            onSuccess(res.data);
        } catch (error) {
            console.error("Error creating:", error);
        }
    };

    const handleEdit = async (values) => {
        try {
            const res = await DealerCatalogService.put(values);
            onSuccess(res.data);
        } catch (error) {
            console.error("Error editing:", error);
        }
    };

    const fetchMakes = async (dlid, catalog) => {
        const makes = await VehicleService.getMakes({ dlid, catalog });
        setMakes(makes);
    };

    const fetchCatalogs = async () => {
        const catalogs = await CatalogService.getAll().then((c) => c.data);
        return catalogs;
    };

    const handleCatalogSelectionChange = async (catalogId, dealerId) => {
        const newCatalogs = catalogs.length === 0 ? await fetchCatalogs() : catalogs;
        const catalog = newCatalogs.find((c) => c.id === catalogId);

        formik.setFieldValue("catalogId", catalogId);
        formik.setFieldValue("catalogName", catalog.name);
        formik.setFieldValue("displayName", catalog.displayName);
        formik.setFieldValue("enDisplayName", catalog.enDisplayName);
        formik.setFieldValue("esDisplayName", catalog.esDisplayName);
        formik.setFieldValue("ptDisplayName", catalog.ptDisplayName);
        formik.setFieldValue("frDisplayName", catalog.frDisplayName);
        formik.setFieldValue("memo", catalog.memo);

        fetchMakes("", catalog.name);
    };

    const handleMakeChange = async (event, make) => {
        const isChecked = event.target.checked;
        const selectedMakeId = make.id;

        if (isChecked) {
            const [years] = await fetchYears(make.id);
            const newSelectionMakesYears = [
                ...formik.values.selectionMakesYears,
                { ...make, years: [years] },
            ];

            setSelectedMakes([...selectedMakes, selectedMakeId]);
            setSelectedMakeId(selectedMakeId);
            formik.setFieldValue("selectionMakesYears", newSelectionMakesYears);
        } else {
            const newSelectionMakesYears = [...formik.values.selectionMakesYears].filter(
                (make) => make.id !== selectedMakeId
            );

            setSelectedMakes(selectedMakes.filter((id) => id !== selectedMakeId));
            setSelectedMakeId(DEFAULT_SELECTED_MAKE_ID);
            formik.setFieldValue("selectionMakesYears", newSelectionMakesYears);
        }

        fetchYears(selectedMakeId);
    };

    const handleToogleSelectAllClick = (newYears = []) => {
        const updatedSelectionMakesYears = [...formik.values.selectionMakesYears].map(
            (makeYear) => {
                if (makeYear.id === selectedMakeId) {
                    return {
                        ...makeYear,
                        years: newYears,
                    };
                }
                return makeYear;
            }
        );
        formik.setFieldValue("selectionMakesYears", updatedSelectionMakesYears);
    };

    const handleYearChange = (event) => {
        const selectedYear = parseInt(event.target.value);
        const isChecked = event.target.checked;

        const updatedSelectionMakesYears = [...formik.values.selectionMakesYears].map(
            (makeYear) => {
                if (makeYear.id === selectedMakeId) {
                    const updatedYears = isChecked
                        ? [...makeYear.years, selectedYear]
                        : makeYear.years.filter((year) => year !== selectedYear);

                    return {
                        ...makeYear,
                        years: updatedYears,
                    };
                }
                return makeYear;
            }
        );

        formik.setFieldValue("selectionMakesYears", updatedSelectionMakesYears);
    };

    const handleYearClose = () => {
        setSelectedMakeId(DEFAULT_SELECTED_MAKE_ID);
        setYears([]);
    };

    const handleEditYearsClick = (makeId) => {
        setSelectedMakeId(makeId);
        fetchYears(makeId);
    };

    const fetchYears = async (makeId) => {
        const catalog = formik.values.catalogName;
        const years = await VehicleService.getYears({
            dlid: dealerId,
            makeId,
            catalog,
            engine: "GAS",
        }).then((y) => y.map((m) => m.name || m.year));

        setYears(years);
        return years;
    };

    const selectedYears =
        (formik.values.selectionMakesYears || []).find((s) => s.id === selectedMakeId)?.years || [];

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                {/* DEALER CATALOGS FIELDS */}
                <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                        <FormControl
                            fullWidth
                            style={{ marginTop: 5 }}
                            error={formik.touched.catalogName && Boolean(formik.errors.catalogName)}
                        >
                            <InputLabel shrink>Catalog</InputLabel>
                            <Select
                                labelId="catalogId-label"
                                id="catalogId"
                                name="catalogId"
                                value={formik.values.catalogId}
                                onChange={(e) => handleCatalogSelectionChange(e.target.value)}
                                fullWidth
                            >
                                {catalogs.map((catalog) => (
                                    <MenuItem key={catalog.id} value={catalog.id}>
                                        {catalog.displayName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="displayName"
                            name="displayName"
                            label="Display Name"
                            value={formik.values.displayName}
                            onChange={formik.handleChange}
                            error={formik.touched.displayName && Boolean(formik.errors.displayName)}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="enDisplayName"
                            name="enDisplayName"
                            label="EN Display Name"
                            value={formik.values.enDisplayName}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.enDisplayName && Boolean(formik.errors.enDisplayName)
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="esDisplayName"
                            name="esDisplayName"
                            label="ES Display Name"
                            value={formik.values.esDisplayName}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.esDisplayName && Boolean(formik.errors.esDisplayName)
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="ptDisplayName"
                            name="ptDisplayName"
                            label="PT Display Name"
                            value={formik.values.ptDisplayName}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.ptDisplayName && Boolean(formik.errors.ptDisplayName)
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="frDisplayName"
                            name="frDisplayName"
                            label="FR Display Name"
                            value={formik.values.frDisplayName}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.frDisplayName && Boolean(formik.errors.frDisplayName)
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="memo"
                            name="memo"
                            label="Memo"
                            value={formik.values.memo}
                            onChange={formik.handleChange}
                            error={formik.touched.memo && Boolean(formik.errors.memo)}
                        />
                    </GridItem>
                    {formik.values.catalogName === "motor" && (
                        <GridItem xs={12} sm={12} md={3}>
                            <CountrySelect
                                selectedCountries={formik.values.selectedCountries.split(",")}
                                onChange={(updatedCountries = []) => {
                                    formik.setFieldValue(
                                        "selectedCountries",
                                        updatedCountries.join(",")
                                    );
                                }}
                            />
                        </GridItem>
                    )}
                </GridContainer>
                {/* DEALER CATALOGS FIELDS*/}

                <Typography variant="h6" gutterBottom style={{ marginTop: 10 }}>
                    Select makes
                </Typography>

                <Grid container spacing={1} style={{ marginTop: 0 }}>
                    {makes.map((make) => (
                        <Grid key={make.id} item xs={3}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={selectedMakes.includes(make.id)}
                                            onChange={(event) => handleMakeChange(event, make)}
                                            value={make.id}
                                        />
                                    }
                                    label={make.name}
                                />
                                {selectedMakes.includes(make.id) && (
                                    <Button
                                        color="primary"
                                        onClick={() => handleEditYearsClick(make.id)}
                                        style={{ marginLeft: "10px" }}
                                    >
                                        Edit years
                                    </Button>
                                )}
                            </div>
                            {make.id === selectedMakeId && (
                                <SelectionYears
                                    years={years}
                                    selectedYears={selectedYears}
                                    onToogleSelectAll={handleToogleSelectAllClick}
                                    onChange={handleYearChange}
                                    onClose={handleYearClose}
                                />
                            )}
                        </Grid>
                    ))}
                </Grid>

                <GridItem xs={12} sm={12} md={12} style={{ textAlign: "center", marginTop: 15 }}>
                    <CustomButton type="submit" color="rose">
                        Save and close
                    </CustomButton>
                </GridItem>
            </form>
        </>
    );
};

export default CreateDealerCatalog;
